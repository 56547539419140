import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutModule} from './layout/layout.module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig} from '@angular/material/snack-bar';
import {ApiService} from './services/base/api.service';
import {SnackbarService} from './services/base/snackbar.service';
import {AddAcceptInterceptor} from './interceptors/add-accept.interceptor';
import {ApiEndpointsService} from './services/base/api-endpoints.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {ReactiveFormsModule} from '@angular/forms';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DatetimeAdapter} from '@ng-matero/extensions/core';
import {MomentDatetimeAdapter} from '@ng-matero/extensions-moment-adapter';
import {LightboxModule} from 'ng-gallery/lightbox';
import {WebformService} from './services/reports/webform.service';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import {FiltersService} from './services/base/filters.service';
import {FuryAlertBoxModule} from '../@fury/shared/alert-box/alert-box.module';
import {PublicNotificationsService} from './services/configuration/public-notifications.service';
import {LayoutService} from './layout/layout.service';

@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

    LightboxModule.withConfig({
      panelClass: 'fullscreen'
    }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true, // environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FuryAlertBoxModule,

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: DatetimeAdapter,
      useClass: MomentDatetimeAdapter,
    },
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'DD-MM-YY'
        },
        display: {
          dateInput: 'DD-MM-YY',
          monthLabel: 'MMMM',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'MMMM',
          monthYearA11yLabel: 'YYYY'
        }
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAcceptInterceptor,
      multi: true
    },
    ApiEndpointsService,
    ApiService,

    LayoutService,
    SnackbarService,
    WebformService,
    FiltersService,
    PublicNotificationsService,
  ]
})
export class AppModule {
}
