import {EventEmitter, Injectable} from '@angular/core';
import * as SimpleBar from 'simplebar';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  scrollbar: SimpleBar;

  public scrollToTop: EventEmitter<null> = new EventEmitter<null>();

  public isPublicNotificationVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }
}
