import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PageHeaderComponent} from './page-header.component';
import {FurySharedModule} from '../../fury-shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FurySharedModule,
  ],
  exports: [
    PageHeaderComponent
  ],
  declarations: [PageHeaderComponent]
})
export class PageHeaderModule {
}
