import {Directive} from '@angular/core';

@Directive({
  selector: '[furyContainer],fury-container',
  host: {
    class: 'fury-container'
  }
})
export class PageContainerDirective {

  constructor() {
  }

}

