import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {PageLayoutModule} from './shared/page-layout/page-layout.module';
import {DialogsModule} from './shared/dialog/dialogs.module';
import {FuryAlertBoxModule} from './shared/alert-box/alert-box.module';
import {RemovableAttachmentModule} from './shared/removable-attachment/removable-attachment.module';
import {MaterialFileInputModule} from 'ngx-material-file-input';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  exports: [
    RouterModule,
    PageLayoutModule,
    DialogsModule,
    FuryAlertBoxModule,

    // External
    FlexLayoutModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    FontAwesomeModule,
    ScrollingModule,
    MaterialFileInputModule,
    RemovableAttachmentModule,
  ],
})
export class FurySharedModule {
}
