import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {switchMap} from 'rxjs/operators';
import {ApiEndpointsService} from '../services/base/api-endpoints.service';

@Injectable()

export class AddAcceptInterceptor implements HttpInterceptor {
  constructor(private http: HttpClient,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.api_endpoint) === -1) {
      return next.handle(request);
    }

    request = request.clone({
      withCredentials: true,
      setHeaders: {
        Accept: `application/json`,
      }
    });

    if (request.method === 'POST') {
      return this.http.get(environment.api_endpoint + this.apiEndpoints.get('webform.csrf-token')).pipe(
        switchMap((response: any) => {
          if (typeof response.token !== 'undefined') {
            request = request.clone({
              setHeaders: {
                'X-CSRF-TOKEN': `${response.token}`
              }
            });
          }

          return next.handle(request);
        }));
    } else {
      return next.handle(request);
    }
  }
}
