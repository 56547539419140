import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/form-selector/form-selector.module').then(m => m.FormSelectorModule),
      },
      {
        path: 'publieke-notificatie',
        loadChildren: () => import('./pages/public-notifications/public-notifications.module').then(m => m.PublicNotificationsModule),
      },
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
