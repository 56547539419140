// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols

import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {ApiService} from './api.service';
import {ServerResponse} from '../../interfaces/base/server.response';
import {ApiEndpointsService} from './api-endpoints.service';
import {HeadCategoriesTypes} from '../../interfaces/configuration/head-categories-types';
import {HeadCategory} from '../../interfaces/routering/head-category';
import {MainCategoriesSurroundings} from '../../interfaces/configuration/main-categories-surroundings';
import {MainCategory} from '../../interfaces/routering/main-category';
import {SubCategory} from '../../interfaces/routering/sub-category';
import {Company} from '../../interfaces/info/company';
import {IntegratedOptions} from '../../interfaces/base/integrated-options';
import {ReporterType} from '../../interfaces/configuration/reporter-type';

@Injectable()

export class FiltersService {

  public filters: any = null;

  private filterData: any = {
    reporterTypes: [],
    headCategoriesTypes: [],
    headCategories: [],
    mainCategoriesSurroundings: [],
    mainCategories: [],
    subCategories: [],
  };


  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  yesNo(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Ja',
          id: true
        },
        {
          name: 'Nee',
          id: false
        }
      ]);
    });
  }

  personPrefix(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Heer',
          id: 'Heer'
        },
        {
          name: 'Mevrouw',
          id: 'Mevrouw'
        },
        {
          name: 'Neutraal',
          id: 'Neutraal',
        }
      ]);
    });
  }

  reporterTypes(): Observable<ReporterType[] | boolean> {
    return new Observable<ReporterType[]>((o: Subscriber<ReporterType[] | boolean>): void => {
      // check if we have cached data available
      if (this.filterData.reporterTypes.length) {
        o.next(this.filterData.reporterTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reporterTypes !== 'undefined') {
            this.filters.reporterTypes.isLoading = true;
          }
        }

        this.api.get(this.apiEndpoints.get('webform.reporter-types')).subscribe(
          (response: ServerResponse): void => {
            if (this.filters !== null) {
              if (typeof this.filters.reporterTypes !== 'undefined') {
                this.filters.reporterTypes.isLoading = false;
              }
            }

            if (typeof response.data !== 'undefined') {
              this.filterData.reporterTypes = response.data;
              o.next(response.data);
            } else {
              o.next(false);
            }
          }, (): void => {
            if (this.filters !== null) {
              if (typeof this.filters.reporterTypes !== 'undefined') {
                this.filters.reporterTypes.isLoading = false;
              }
            }

            o.next(false);
          });
      }
    });
  }

  headCategoriesTypes(): Observable<HeadCategoriesTypes[] | boolean> {
    return new Observable<HeadCategoriesTypes[]>((o: Subscriber<HeadCategoriesTypes[] | boolean>): void => {
      // check if we have cached data available
      if (this.filterData.headCategoriesTypes.length) {
        o.next(this.filterData.headCategoriesTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.headCategoriesTypes !== 'undefined') {
            this.filters.headCategoriesTypes.isLoading = true;
          }
        }

        this.api.get(this.apiEndpoints.get('webform.report-types')).subscribe(
          (response: ServerResponse): void => {
            if (this.filters !== null) {
              if (typeof this.filters.headCategoriesTypes !== 'undefined') {
                this.filters.headCategoriesTypes.isLoading = false;
              }
            }

            if (typeof response.data !== 'undefined') {
              o.next(response.data);
              this.filterData.headCategoriesTypes = response.data;
            } else {
              o.next(false);
            }
          },
          (): void => {
            if (this.filters !== null) {
              if (typeof this.filters.headCategoriesTypes !== 'undefined') {
                this.filters.headCategoriesTypes.isLoading = false;
              }
            }

            o.next(false);
          });
      }
    });
  }

  mainCategoriesSurroundings(): Observable<MainCategoriesSurroundings[] | boolean> {
    return new Observable<MainCategoriesSurroundings[]>((o: Subscriber<MainCategoriesSurroundings[] | boolean>): void => {
      // check if we have cached data available
      if (this.filterData.mainCategoriesSurroundings.length) {
        o.next(this.filterData.mainCategoriesSurroundings);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.mainCategoriesSurroundings !== 'undefined') {
            this.filters.mainCategoriesSurroundings.isLoading = true;
          }
        }

        this.api.get(this.apiEndpoints.get('webform.surroundings')).subscribe(
          (response: ServerResponse): void => {
            if (this.filters !== null) {
              if (typeof this.filters.mainCategoriesSurroundings !== 'undefined') {
                this.filters.mainCategoriesSurroundings.isLoading = false;
              }
            }

            if (typeof response.data !== 'undefined') {
              o.next(response.data);
              this.filterData.mainCategoriesSurroundings = response.data;
            } else {
              o.next(false);
            }
          },
          (): void => {
            if (this.filters !== null) {
              if (typeof this.filters.mainCategoriesSurroundings !== 'undefined') {
                this.filters.mainCategoriesSurroundings.isLoading = false;
              }
            }

            o.next(false);
          });
      }
    });
  }

  headCategories(surroundingId: number = null): Observable<HeadCategory[] | boolean> {
    return new Observable<HeadCategory[]>((o: Subscriber<HeadCategory[] | boolean>): void => {
      // check if we have cached data available
      if (typeof this.filterData.headCategories[surroundingId] !== 'undefined' && this.filterData.headCategories[surroundingId].length) {
        o.next(this.filterData.headCategories[surroundingId]);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.headCategories !== 'undefined') {
            this.filters.headCategories.isLoading = true;
          }
        }

        this.api.get(this.apiEndpoints.get('webform.head-categories', {':id': surroundingId})).subscribe(
          (response: ServerResponse): void => {
            if (this.filters !== null) {
              if (typeof this.filters.headCategories !== 'undefined') {
                this.filters.headCategories.isLoading = false;
              }
            }

            if (typeof response.data !== 'undefined') {
              o.next(response.data);
              this.filterData.headCategories[surroundingId] = response.data;
            } else {
              o.next(false);
            }
          },
          (): void => {
            if (this.filters !== null) {
              if (typeof this.filters.headCategories !== 'undefined') {
                this.filters.headCategories.isLoading = false;
              }
            }

            o.next(false);
          });
      }
    });
  }

  mainCategories(head_category_id: number = null, surroundingId: number = null): Observable<MainCategory[] | boolean> {
    return new Observable<MainCategory[]>((o: Subscriber<MainCategory[] | boolean>): void => {
      // check if we have cached data available
      if (
        head_category_id === null && typeof this.filterData.mainCategories['null'] !== 'undefined' && this.filterData.mainCategories['null'].length ||
        head_category_id !== null && surroundingId === null && typeof this.filterData.mainCategories[head_category_id] !== 'undefined' && this.filterData.mainCategories[head_category_id].length ||
        head_category_id !== null && surroundingId !== null &&
        typeof this.filterData.mainCategories['surroundings'] !== 'undefined' &&
        typeof this.filterData.mainCategories['surroundings'][head_category_id] !== 'undefined' &&
        typeof this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] !== 'undefined' &&
        this.filterData.mainCategories['surroundings'][head_category_id][surroundingId].length
      ) {
        if (head_category_id === null) {
          o.next(this.filterData.mainCategories['null']);
        } else if (surroundingId !== null) {
          o.next(this.filterData.mainCategories['surroundings'][head_category_id][surroundingId]);
        } else {
          o.next(this.filterData.mainCategories[head_category_id]);
        }
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.mainCategories !== 'undefined') {
            this.filters.mainCategories.isLoading = true;
          }
        }

        this.api.get(this.apiEndpoints.get('webform.head-categories.main-categories', {
          ':head_category_id': head_category_id,
          ':id': surroundingId
        })).subscribe(
          (response: ServerResponse): void => {
            if (this.filters !== null) {
              if (typeof this.filters.mainCategories !== 'undefined') {
                this.filters.mainCategories.isLoading = false;
              }
            }
            if (typeof response.data !== 'undefined') {
              o.next(response.data);
              if (surroundingId !== null) {
                if (typeof this.filterData.mainCategories['surroundings'] === 'undefined') {
                  this.filterData.mainCategories['surroundings'] = [];
                }

                if (typeof this.filterData.mainCategories['surroundings'][head_category_id] === 'undefined') {
                  this.filterData.mainCategories['surroundings'][head_category_id] = [];
                }

                if (typeof this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] === 'undefined') {
                  this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] = [];
                }
                this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] = response.data;
              } else if (head_category_id !== null) {
                this.filterData.mainCategories[head_category_id] = response.data;
              } else {
                this.filterData.mainCategories['null'] = response.data;
              }
            } else {
              o.next(false);
            }
          },
          (): void => {
            if (this.filters !== null) {
              if (typeof this.filters.mainCategories !== 'undefined') {
                this.filters.mainCategories.isLoading = false;
              }
            }

            o.next(false);
          });
      }
    });
  }

  subCategories(head_category_id: number, main_category_id: number = null): Observable<SubCategory[] | boolean> {
    return new Observable<SubCategory[]>((o: Subscriber<SubCategory[] | boolean>): void => {
      if (main_category_id === null && typeof this.filterData.subCategories['null'] !== 'undefined' && this.filterData.subCategories['null'].length ||
        main_category_id !== null && typeof this.filterData.subCategories[main_category_id] !== 'undefined' && this.filterData.subCategories[main_category_id].length) {
        if (main_category_id !== null) {
          o.next(this.filterData.subCategories[main_category_id]);
        } else {
          o.next(this.filterData.subCategories['null']);
        }
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.subCategories !== 'undefined') {
            this.filters.subCategories.isLoading = true;
          }
        }

        this.api.get(this.apiEndpoints.get('webform.head-categories.main-categories.sub-categories', {
          ':head_category_id': head_category_id,
          ':main_category_id': main_category_id
        })).subscribe(
          (response: ServerResponse): void => {
            if (this.filters !== null) {
              if (typeof this.filters.subCategories !== 'undefined') {
                this.filters.subCategories.isLoading = false;
              }
            }

            if (typeof response.data !== 'undefined') {
              o.next(response.data);
              if (main_category_id !== null) {
                this.filterData.subCategories[main_category_id] = response.data;
              } else {
                this.filterData.subCategories['null'] = response.data;
              }
            } else {
              o.next(false);
            }
          },
          (): void => {
            if (this.filters !== null) {
              if (typeof this.filters.subCategories !== 'undefined') {
                this.filters.subCategories.isLoading = false;
              }
            }

            o.next(false);
          });
      }
    });
  }

  companies(search: string): Observable<Company[] | boolean> {
    return new Observable<Company[]>((o: Subscriber<Company[] | boolean>): void => {
      // get data from server
      if (this.filters !== null) {
        if (typeof this.filters.companies !== 'undefined') {
          this.filters.companies.isLoading = true;
        }
      }

      this.api.get(this.apiEndpoints.get('webform.companies', {':search': search})).subscribe(
        (response: ServerResponse): void => {
          if (this.filters !== null) {
            if (typeof this.filters.companies !== 'undefined') {
              this.filters.companies.isLoading = false;
            }
          }

          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          if (this.filters !== null) {
            if (typeof this.filters.companies !== 'undefined') {
              this.filters.companies.isLoading = false;
            }
          }

          o.next(false);
        });
    });
  }
}
