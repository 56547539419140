import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
  selector: 'fury-removable-attachment',
  templateUrl: './removable-attachment.component.html',
  styleUrls: ['./removable-attachment.component.scss'],
})

export class RemovableAttachmentComponent {

  @Input() attachment: File = null;
  @Input() filename: string = null;
  @Input() size: number = null;
  @Input() removable: boolean = true;

  @Output() destroy: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('className') className = 'fury-removable-attachment';

  constructor() {
  }

  removeAttachment(): void {
    this.destroy.emit(true);
  }
}
