<footer>
    <div class="footer-container">
        <div class="footer">
            <div class="footer-div">
                <div class="footer-tittle">Overlast melden?</div>
                <div class="footer-sub-title">U kunt 24/7 een milieuklacht indienen.</div>
                <div class="footer-button-wrap">
                    <a class="w-button" href="{{ environment.webform_url }}" title="Meldformulier milieuklachten">
                        Meldformulier
                        <mat-icon>arrow_circle_right</mat-icon>
                    </a>
                    <div class="text-line-block max-width">
                        Of bel <a class="link" href="tel:+31736812821" title="Telefoonnummer MilieuklachtenCentrale Brabant">073681 28 21</a> (Brabant)
                        of <a class="link" href="tel:+310118412323" title="Telefoonnummer MilieuklachtenCentrale Zeeland">0118 41 2323</a> (Zeeland)
                    </div>
                </div>
            </div>
            <div class="footer-contant">
                <div class="heading-h3 margin-buttom-18">Over de MilieuKlachtenCentrale</div>
                <p class="footer-paragraph">
                    De MilieuKlachtenCentrale is een samenwerking van de drie Brabantse omgevingsdiensten (ODBN, OMWB, ODZOB) en de
                    regionale uitvoeringsdienst van Zeeland (RUD-Zeeland). Wij voeren deze taken uit namens de gemeenten en provincies in Brabant en Zeeland.
                    Ook de <a href="https://www.zwemwater.nl/" target="_blank" title="Zwemwater">Zwemwatertelefoon</a> en het telefoonnummer van
                    <a href="https://ssibbrabant.nl/" target="_blank" title="Samen Sterk in Brabant">SSiB</a> komt uit op de MilieuKlachtenCentrale.
                </p>
            </div>
        </div>
        <div class="last-div">
            <div class="last-text">
                © 2023 Milieu Klachtencentrale |
                <a class="link-text" href="{{ environment.homepage_url }}/disclaimer" title="Bekijk de pagina disclaimer op omwb.nl">Disclaimer</a> |
                <a class="link-text" href="{{ environment.homepage_url }}/privacy" title="Bekijk de pagina privacy">Privacy</a> |
            </div>
        </div>
    </div>
</footer>
