<fury-page-layout-header>
    <fury-container class="header">
        <a class="logo" href="{{ environment.homepage_url }}">
            <img alt="Milieu Klachten Centrale" src="assets/img/logo.svg">
        </a>
        <a *ngIf="isReportButtonVisible" class="w-button" routerLink="/" title="Meldformulier milieuklachten">
            Meldformulier
            <mat-icon>arrow_circle_right</mat-icon>
        </a>
    </fury-container>
</fury-page-layout-header>
