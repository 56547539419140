import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {filter, map, startWith} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {checkRouterChildsData} from '../../@fury/utils/check-router-childs-data';
import {BackdropDirective} from '../../@fury/shared/backdrop/backdrop.directive';
import {CdkScrollable} from '@angular/cdk/overlay';
import {LayoutService} from './layout.service';
import {environment} from '../../environments/environment';
import {SwUpdate} from '@angular/service-worker';
import {ApiService} from '../services/base/api.service';
import {Observable} from 'rxjs';
import {PublicNotification} from '../interfaces/configuration/public-notification';
import {PublicNotificationsService} from '../services/configuration/public-notifications.service';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild('backdrop') backdrop: BackdropDirective;
  @ViewChildren(CdkScrollable) cdkScrollable;

  scrollDisabled$: Observable<any> = this.router.events.pipe(
    filter<NavigationEnd>((event: NavigationEnd): boolean => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  public badConnection: boolean = false;
  public isStaging: boolean = false;
  public publicNotification: PublicNotification = null;
  public isPublicNotificationVisible: boolean = false;

  constructor(private router: Router,
              private swUpdate: SwUpdate,
              private publicNotificationsService: PublicNotificationsService,
              public layoutService: LayoutService,
              public api: ApiService,
  ) {
  }

  ngOnInit(): void {
    this.layoutService.scrollToTop.subscribe((): void => {
      this.onActivate();
    });

    this.layoutService.isPublicNotificationVisible.subscribe((value: boolean): void => {
      setTimeout((): void => {
        this.isPublicNotificationVisible = value;
      }, 0);
    });

    this.publicNotificationsService.all().subscribe((data: PublicNotification): void => {
      if (data) {
        this.publicNotification = data;
      }
    });

    this.swUpdate.unrecoverable.subscribe((): void => {
      this.badConnection = true;
    });

    if (environment.appVersionAddon === '-staging') {
      this.isStaging = true;
    }
  }

  onActivate(): void {
    if (typeof this.cdkScrollable !== 'undefined') {
      if (window.location.hash !== '') {
        if (typeof document.getElementById(window.location.hash.replace('#', '')) !== 'undefined') {
          if (document.getElementById(window.location.hash.replace('#', '')) !== null) {
            setTimeout((): void => {
              document.getElementById(window.location.hash.replace('#', '')).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              });
            }, 250);
          }
        }
      } else {
        this.cdkScrollable.first.scrollTo({top: 0});
      }
    }
  }

  ngOnDestroy(): void {
  }
}

