<fury-page-layout>
    <fury-page-layout-content @fadeInUp>
        <fury-container>
            <form [formGroup]="webformService.normalForm">
                <div fxLayout="row" fxLayoutGap="36px">
                    <div (sectionChange)="onSectionChange($event)" [spiedTags]="['H2']" furyScrollSpy fxFlex="100" fxFlex.gt-sm="80"
                         fxLayout="column">
                        <h1>Melding doen</h1>
                        <p>
                            Geef aan wat er aan de hand is en waar u dit heeft gesignaleerd. Wij nemen uw melding zo spoedig mogelijk in behandeling.
                        </p>
                        <p>
                            <i>Vragen gemarkeerd met een * zijn verplicht om in te vullen.</i>
                        </p>
                        <h2 id="melding">Welke overlast ervaart u?</h2>
                        <div [class.has-error]="webformService.normalForm.controls.surrounding_id.errors && (webformService.normalForm.controls.surrounding_id.touched || controlForm)"
                             class="container">
                            <mat-icon aria-hidden="true">warning</mat-icon>
                            <mat-label>Waar vindt de overlast plaats? <sup>*</sup></mat-label>
                            <div class="switcher">
                                <div *ngFor="let data of filters.mainCategoriesSurroundings.data">
                                    <input [value]="data.id" formControlName="surrounding_id" id="surrounding_id_{{ data.id }}" type="radio">
                                    <label for="surrounding_id_{{ data.id }}">{{ data.name }}</label>
                                </div>
                            </div>
                            <mat-error>Waar vindt de overlast plaats is niet gevuld.</mat-error>
                            <fury-loading-overlay [isLoading]="filters.mainCategoriesSurroundings.isLoading"></fury-loading-overlay>
                        </div>
                        <div *ngIf="filters.headCategories.data.length || filters.headCategories.isLoading"
                             [class.has-error]="webformService.normalForm.controls.head_category_id.errors && (webformService.normalForm.controls.head_category_id.touched || controlForm)"
                             class="container">
                            <mat-icon aria-hidden="true">warning</mat-icon>
                            <mat-label>Welke overlast ervaart u? <sup>*</sup></mat-label>
                            <div *ngIf="filters.headCategories.data" class="head-category-container">
                                <div *ngFor="let data of filters.headCategories.data"
                                     [class.active]="webformService.normalForm.controls.head_category_id.value === data.id"
                                     class="head-category">
                                    <input [value]="data.id" formControlName="head_category_id" id="head_category_id{{ data.id }}" type="radio">
                                    <div>
                                        <img [alt]="data.name" [src]="data.icon">
                                        <span>{{ data.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <mat-error>Welke overlast ervaart u is niet gevuld.</mat-error>
                            <fury-loading-overlay [isLoading]="filters.headCategories.isLoading"></fury-loading-overlay>
                        </div>
                        <div *ngIf="selectedHeadCategoryNote" [innerHTML]="selectedHeadCategoryNote" class="note"></div>
                        <div *ngIf="filters.mainCategories.data.length || filters.mainCategories.isLoading"
                             [class.has-error]="webformService.normalForm.controls.main_category_id.errors && (webformService.normalForm.controls.main_category_id.touched || controlForm)"
                             class="container">
                            <mat-icon aria-hidden="true">warning</mat-icon>
                            <mat-label>Soort overlast <sup>*</sup></mat-label>
                            <select *ngIf="filters.mainCategories.data" formControlName="main_category_id">
                                <option [value]="null">Selecteer soort overlast</option>
                                <option *ngFor="let data of filters.mainCategories.data" [value]="data.id">{{ data.name }}</option>
                            </select>
                            <fury-loading-overlay [isLoading]="filters.mainCategories.isLoading"></fury-loading-overlay>
                            <mat-error>Soort overlast is niet gevuld.</mat-error>
                        </div>
                        <div *ngIf="selectedMainCategoryNote" [innerHTML]="selectedMainCategoryNote" class="note"></div>
                        <div *ngIf="filters.subCategories.data.length || filters.subCategories.isLoading"
                             [class.has-error]="webformService.normalForm.controls.sub_category_id.errors && (webformService.normalForm.controls.sub_category_id.touched || controlForm)"
                             class="container">
                            <mat-icon aria-hidden="true">warning</mat-icon>
                            <mat-label>Specificeer het soort overlast <sup>*</sup></mat-label>
                            <select *ngIf="filters.subCategories.data" formControlName="sub_category_id">
                                <option [value]="null">Selecteer specificeer het soort overlast</option>
                                <option *ngFor="let data of filters.subCategories.data" [value]="data.id">{{ data.name }}</option>
                            </select>
                            <fury-loading-overlay [isLoading]="filters.subCategories.isLoading"></fury-loading-overlay>
                            <mat-error>Specificeer het soort overlast is niet gevuld.</mat-error>
                        </div>
                        <div *ngIf="selectedSubCategoryNote" [innerHTML]="selectedSubCategoryNote" class="note"></div>
                        <div class="container">
                            <mat-label>Toelichting overlast</mat-label>
                            <textarea formControlName="description" placeholder="Geef hier aan wat de overlast is en/of hoe lang de overlast al gaande is"
                                      maxlength="5000" rows="4" class="sentry-mask"></textarea>
                            <mat-hint class="word-counter">{{ webformService.normalForm.controls.description.value.length }} / 5000</mat-hint>
                        </div>
                        <div class="container">
                            <mat-label>Wanneer heeft u de overlast waargenomen?</mat-label>
                            <input [max]="maxNoticedAt" class="noticed-at" formControlName="noticed_at_fix" type="datetime-local">
                        </div>
                        <div [class.has-error]="webformService.normalForm.controls.suspect_known.errors && (webformService.normalForm.controls.suspect_known.touched || controlForm)"
                             class="container">
                            <mat-icon aria-hidden="true">warning</mat-icon>
                            <mat-label>Heeft u enig idee wie of wat de vermoedelijke veroorzaker is van de overlast? <sup>*</sup></mat-label>
                            <div class="switcher">
                                <div *ngFor="let data of filters.yesNo.data">
                                    <input [value]="data.id" formControlName="suspect_known" id="suspect_known_{{ data.id }}" type="radio">
                                    <label for="suspect_known_{{ data.id }}">{{ data.name }}</label>
                                </div>
                            </div>
                            <mat-error>Heeft u enig idee wie of wat de vermoedelijke veroorzaker is van de overlast is niet gevuld.</mat-error>
                            <fury-loading-overlay [isLoading]="filters.yesNo.isLoading"></fury-loading-overlay>
                        </div>
                        <ng-container *ngIf="webformService.normalForm.controls.suspect_known.value">
                            <div [class.has-error]="webformService.normalForm.controls.suspect_type.errors && (webformService.normalForm.controls.suspect_type.touched || controlForm)"
                                 class="container">
                                <mat-icon aria-hidden="true">warning</mat-icon>
                                <mat-label>Is dit een bedrijf of een particulier? <sup>*</sup></mat-label>
                                <div class="switcher">
                                    <div>
                                        <input formControlName="suspect_type" id="suspect_type_bedrijf" type="radio" value="Bedrijf">
                                        <label for="suspect_type_bedrijf">Bedrijf</label>
                                    </div>
                                    <div>
                                        <input formControlName="suspect_type" id="suspect_type_particulier" type="radio" value="Particulier">
                                        <label for="suspect_type_particulier">Particulier</label>
                                    </div>
                                </div>
                                <mat-error>Is dit een bedrijf of een particulier is niet gevuld.</mat-error>
                            </div>
                        </ng-container>
                        <div *ngIf="webformService.normalForm.controls.suspect_type.value === 'Particulier' && webformService.normalForm.controls.surrounding_id.value !== 2"
                             class="note">
                            Melding over particulieren/personen nemen wij niet in behandeling, neem hiervoor contact op met uw lokale organisatie zoals uw
                            gemeente.
                        </div>
                        <ng-container *ngIf="webformService.normalForm.controls.suspect_type.value === null ||
                                        webformService.normalForm.controls.suspect_type.value === 'Bedrijf' ||
                                        webformService.normalForm.controls.suspect_type.value === 'Onbekend' ||
                                        (webformService.normalForm.controls.suspect_type.value === 'Particulier' && webformService.normalForm.controls.surrounding_id.value === 2)">
                            <ng-container *ngIf="webformService.normalForm.controls.suspect_known.value">
                                <div [class.has-error]="webformService.normalForm.controls.suspect_name.errors && (webformService.normalForm.controls.suspect_name.touched || controlForm)"
                                     class="container">
                                    <mat-icon aria-hidden="true">warning</mat-icon>
                                    <mat-label>Naam/organisatie vermoedelijke veroorzaker <sup>*</sup></mat-label>
                                    <input [matAutocomplete]="suggestSuspectName" formControlName="suspect_name">
                                    <fury-loading-overlay [isLoading]="filters.companies.isLoading"></fury-loading-overlay>
                                    <mat-autocomplete #suggestSuspectName="matAutocomplete" [displayWith]="formatSuspectNameDropdown">
                                        <mat-option *ngFor="let option of filters.companies.data" [value]="option">
                                            {{ option.name }}
                                            <small>
                                                ({{ option.streetname}} {{ option.house_number }}{{ option.house_number_addition }}
                                                , {{ option.postalcode }} {{ option.city }})
                                            </small>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error>Naam/organisatie vermoedelijke veroorzaker is niet gevuld.</mat-error>
                                </div>
                            </ng-container>
                                <div [class.has-error]="webformService.normalForm.controls.suspect_location.errors && (webformService.normalForm.controls.suspect_location.touched || controlForm)"
                                     class="container">
                                    <mat-icon aria-hidden="true">warning</mat-icon>
                                    <mat-label>Kent u de locatie van de vermoedelijke veroorzaker? <sup>*</sup></mat-label>
                                    <p>Vul hieronder het adres in van de vermoedelijke veroorzaker. Indien dit niet bekend is, vul dan in ieder geval de gemeente in.</p>
                                    <input [matAutocomplete]="suggestSuspectLocation" formControlName="suspect_location">
                                    <fury-loading-overlay [isLoading]="isLoadingSuspectLocations"></fury-loading-overlay>
                                    <mat-autocomplete #suggestSuspectLocation="matAutocomplete" [displayWith]="formatLocationDropdown">
                                        <mat-option *ngFor="let option of suggestSuspectLocations" [value]="option">
                                            {{ option.weergavenaam }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error>Kent u de locatie van de vermoedelijke veroorzaker is niet gevuld.</mat-error>
                                </div>
                            <div (leafletClick)="onMapClick($event, 'suspect')" (leafletMapReady)="leafletMapService.initSuspect($event)"
                                 [leafletOptions]="leafletMapService.mapOptionsSuspect"
                                 class="map"
                                 id="suspect-map"
                                 leaflet></div>
                            <leaflet-loading-control
                                    [map]='leafletMapService.mapContainerSuspect'
                                    [options]="leafletMapService.loadingOptions"
                            ></leaflet-loading-control>
                            <div class="container">
                                <mat-icon aria-hidden="true">warning</mat-icon>
                                <mat-label>Heeft u een foto of andere informatie over de overlast?</mat-label>
                                <div (click)="uploadAttachments.open()" class="container-uploader">
                                    Sleep uw bestanden hierheen of selecteer de bestanden (klik hier)
                                    <ngx-mat-file-input #uploadAttachments
                                                        (change)="detectUploadAttachments($event)"
                                                        [multiple]="true"
                                                        formControlName="attachmentsContainer"></ngx-mat-file-input>
                                </div>
                                <div #attachmentsPlaceholder></div>
                                <mat-hint class="mat-error" *ngIf="attachmentIgnored">
                                    Eén van de door u aangeleverde bestanden wordt door ons niet geaccepteerd en zal niet worden verstuurd:
                                    {{ attachmentIgnored }}
                                </mat-hint>
                                <mat-hint class="mat-error" *ngIf="attachmentCountExceeded">
                                    Er kunnen maximaal 6 bijlages worden toegevoegd.
                                </mat-hint>
                                <mat-hint class="mat-error" *ngIf="attachmentTotalSizeExceeded">
                                    De maximale grootte voor bijlage(s) is 30MB.
                                </mat-hint>
                                <mat-hint>
                                    Maximaal 6 bestanden toevoegen in bestandsformat .jpg, .png, .pdf, .mp4, .flv, .docx, en .xlsx niet groter als 30 MB in
                                    totaal.
                                </mat-hint>
                            </div>
                            <h2 id="melding-locatie">Waar ervaart u de overlast?</h2>
                            <p *ngIf="!webformService.normalForm.controls.surrounding_id.value || webformService.normalForm.controls.surrounding_id.value === 1">
                                Het adres kunt u intypen op het veld hieronder of de locatie van de overlast kan makkelijk worden toegevoegd door middel van het slepen van de rode punt op de kaart.
                            </p>
                            <p *ngIf="webformService.normalForm.controls.surrounding_id.value === 2">
                                De locatie van de overlast kan makkelijk worden toegevoegd door middel van het slepen van de rode punt op de kaart.
                            </p>
                            <div [class.has-error]="webformService.normalForm.controls.report_location.errors && (webformService.normalForm.controls.report_location.touched || controlForm)"
                                 class="container">
                                <mat-icon aria-hidden="true">warning</mat-icon>
                                <mat-label *ngIf="!webformService.normalForm.controls.surrounding_id.value || webformService.normalForm.controls.surrounding_id.value === 1">
                                    Start met het typen van de straatnaam en het huisnummer, kies daarna de juiste plaats <sup>*</sup>
                                </mat-label>
                                <mat-label *ngIf="webformService.normalForm.controls.surrounding_id.value === 2">
                                    GPS coördinaten geselecteerde locatie overlast <sup>*</sup>
                                </mat-label>
                                <input [matAutocomplete]="suggestReportLocation" formControlName="report_location" (blur)="validateReportLocation()" [readonly]="webformService.normalForm.controls.surrounding_id.value === 2">
                                <fury-loading-overlay [isLoading]="isLoadingReportLocations"></fury-loading-overlay>
                                <mat-autocomplete #suggestReportLocation="matAutocomplete" [displayWith]="formatLocationDropdown">
                                    <mat-option *ngFor="let option of suggestReportLocations" [value]="option">
                                        {{ option.weergavenaam }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="!webformService.normalForm.controls.report_location.errors?.invalid && (!webformService.normalForm.controls.surrounding_id.value || webformService.normalForm.controls.surrounding_id.value === 1)">
                                    Start met het typen van de straatnaam en het huisnummer, kies daarna de juiste plaats is niet gevuld.
                                </mat-error>
                                <mat-error *ngIf="webformService.normalForm.controls.report_location.errors?.invalid">
                                    Selecteer het adres in de locatie suggestie voordat u verder gaat.
                                </mat-error>
                                <mat-error *ngIf="webformService.normalForm.controls.surrounding_id.value === 2">
                                    GPS coördinaten geselecteerde locatie overlast is niet gevuld.
                                </mat-error>
                            </div>
                            <div (leafletClick)="onMapClick($event)" (leafletMapReady)="leafletMapService.init($event)"
                                 [leafletOptions]="leafletMapService.mapOptions"
                                 class="map"
                                 id="map"
                                 leaflet></div>
                            <leaflet-loading-control
                                    [map]='leafletMapService.mapContainer'
                                    [options]="leafletMapService.loadingOptions"
                            ></leaflet-loading-control>
                            <div class="container">
                                <mat-label>Toelichting locatie overlast</mat-label>
                                <textarea formControlName="report_location_description" id="report_location_description" rows="2"
                                          maxlength="500" placeholder="Welke informatie is relevant voor de locatie overlast?" class="sentry-mask"></textarea>
                                <mat-hint class="word-counter">{{ webformService.normalForm.controls.report_location_description.value.length }} / 500</mat-hint>
                            </div>
                            <div *ngIf="shouldGeocodeReportLocation !== false && webformService.normalForm.controls.reporter_anonymous.value !== true && webformService.normalForm.controls.report_streetname.value"
                                 [class.has-error]="webformService.normalForm.controls.same_as_reporter_address.errors && (webformService.normalForm.controls.same_as_reporter_address.touched || controlForm)"
                                 class="container">
                                <mat-label>Is de locatie van overlast hetzelfde als uw woonadres? <sup>*</sup></mat-label>
                                <div class="switcher">
                                    <div *ngFor="let data of filters.yesNo.data">
                                        <input [value]="data.id" formControlName="same_as_reporter_address"
                                               id="same_as_reporter_address_{{ data.id }}" type="radio">
                                        <label for="same_as_reporter_address_{{ data.id }}">{{ data.name }}</label>
                                    </div>
                                </div>
                                <fury-loading-overlay [isLoading]="filters.yesNo.isLoading"></fury-loading-overlay>
                                <mat-error>Is de locatie van overlast hetzelfde als uw woonadres is niet gevuld.</mat-error>
                            </div>
                            <h2 id="melder">Uw contact gegevens</h2>
                            <p>
                                Door uw persoonlijke gegevens in te vullen kunnen wij u op de hoogte houden en indien nodig, contact opnemen naar aanleiding van
                                uw
                                melding. De gegevens worden gebruikt om u op de hoogte te houden en uw melding in behandeling te nemen en zo mogelijk, op te
                                lossen.
                            </p>
                            <div class="container">
                                <div class="checkbox grey">
                                    <input [value]="true" formControlName="reporter_anonymous" id="reporter_anonymous" type="checkbox">
                                    <label for="reporter_anonymous">
                                        <span></span>
                                        Dit formulier anoniem indienen
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="webformService.normalForm.controls.reporter_anonymous.value" class="note">
                                Als u een melding anoniem indient, kan het zijn dat wij onvoldoende informatie hebben om een onderzoek op te starten. We kunnen
                                u niet bellen of mailen voor meer informatie. We kunnen ook niet achterhalen of de overlast op dit moment nog speelt. We vragen
                                u daarom om uw contactgegevens aan ons door te geven. Wij delen uw contactgegevens niet met de (vermoedelijke) veroorzaker.
                            </div>
                            <ng-container *ngIf="!webformService.normalForm.controls.reporter_anonymous.value">
                                <div [class.has-error]="webformService.normalForm.controls.reporter_type_id.errors && (webformService.normalForm.controls.reporter_type_id.touched || controlForm)"
                                     class="container">
                                    <mat-icon aria-hidden="true">warning</mat-icon>
                                    <mat-label>Bent u een persoon of een bedrijf? <sup>*</sup></mat-label>
                                    <div class="switcher">
                                        <div *ngFor="let data of filters.reporterTypes.data">
                                            <input [value]="data.id" formControlName="reporter_type_id" id="reporter_type_id_{{ data.id }}" type="radio">
                                            <label for="reporter_type_id_{{ data.id }}">{{ data.display_name }}</label>
                                        </div>
                                    </div>
                                    <mat-error>Bent u een persoon of een bedrijf is niet gevuld.</mat-error>
                                    <fury-loading-overlay [isLoading]="filters.reporterTypes.isLoading"></fury-loading-overlay>
                                </div>
                                <div *ngIf="webformService.normalForm.controls.reporter_type_id.value === 2" class="container">
                                    <mat-label>Uw bedrijfsnaam</mat-label>
                                    <input formControlName="reporter_organisation" type="text" class="sentry-mask">
                                </div>
                                <div class="container" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
                                    <div fxFlex.gt-xs="20">
                                        <mat-label>Aanhef</mat-label>
                                        <select formControlName="reporter_prefix" class="sentry-mask">
                                            <option *ngFor="let data of filters.personPrefix.data" [value]="data.id">{{ data.name }}</option>
                                        </select>
                                    </div>
                                    <div fxFlex.gt-xs="15">
                                        <mat-label>Voorletters</mat-label>
                                        <input formControlName="reporter_initials" type="text" class="sentry-mask">
                                    </div>
                                    <div fxFlex.gt-xs="15">
                                        <mat-label>Tussenvoegsel</mat-label>
                                        <input formControlName="reporter_middlename" type="text" class="sentry-mask">
                                    </div>
                                    <div fxFlex.gt-xs="50">
                                        <mat-label>Achternaam</mat-label>
                                        <input formControlName="reporter_lastname" type="text" class="sentry-mask">
                                    </div>
                                </div>
                                <div class="container">
                                    <mat-label>Adres</mat-label>
                                    <input [matAutocomplete]="suggestReporterLocation" formControlName="reporter_location" class="sentry-mask">
                                    <fury-loading-overlay [isLoading]="isLoadingReporterLocations"></fury-loading-overlay>
                                    <mat-autocomplete #suggestReporterLocation="matAutocomplete" [displayWith]="formatLocationDropdown">
                                        <mat-option *ngFor="let option of suggestReporterLocations" [value]="option">
                                            {{ option.weergavenaam }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
                                    <div fxFlex.gt-xs="50">
                                        <div [class.has-error]="webformService.normalForm.controls.reporter_email.errors && (webformService.normalForm.controls.reporter_email.touched || controlForm)"
                                             class="container">
                                            <mat-icon aria-hidden="true">warning</mat-icon>
                                            <mat-label>E-mail <sup
                                                    *ngIf="webformService.normalForm.controls.reporter_email.hasValidator(Validators.required)">*</sup>
                                            </mat-label>
                                            <input formControlName="reporter_email" type="email" class="sentry-mask">
                                            <mat-error>E-mail is niet gevuld.</mat-error>
                                        </div>
                                    </div>
                                    <div fxFlex.gt-xs="50">
                                        <div [class.has-error]="webformService.normalForm.controls.reporter_phone.errors && (webformService.normalForm.controls.reporter_phone.touched || controlForm)"
                                             class="container">
                                            <mat-label>Telefoonnummer</mat-label>
                                            <input formControlName="reporter_phone" type="tel" class="sentry-mask">
                                            <mat-error>Voer het telefoonnummer in zonder spaties of streepjes.</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="container">
                                    <div class="checkbox">
                                        <input [value]="true" checked="checked" formControlName="reporter_notify" id="reporter_notify" type="checkbox">
                                        <label for="reporter_notify">
                                            <span></span>
                                            Ja, ik wil op de hoogte worden gehouden van mijn melding. Hiervoor is het invullen van een e-mail verplicht.
                                        </label>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="controlForm && webformService.normalForm.invalid" class="note warning">
                                Let op: Niet alle (verplichte) velden zijn op de correcte wijze ingevuld.
                            </div>
                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" fxLayoutGap.gt-sm="32px">
                                <button color="secondary" fxFlex="100" fxFlex.gt-sm="50" mat-button routerLink="/"
                                        type="button">
                                    Annuleer melding
                                    <mat-icon>cancel</mat-icon>
                                </button>
                                <button (click)="checkForm()" color="primary" fxFlex="100" fxFlex.gt-sm="50" mat-button>
                                    Verstuur melding
                                    <mat-icon>play_circle_outline</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                    <div class="steps-container" fxFlex.gt-sm="212px" fxHide fxLayout="row" fxLayoutAlign="flex-end" fxShow.gt-sm>
                        <ul class="steps">
                            <li [class.active]="currentSection === 'melding'" class="step">Uw melding</li>
                            <li [class.active]="currentSection === 'melding-locatie'" class="step">Locatie van uw melding</li>
                            <li [class.active]="currentSection === 'melder'" class="step">Uw contact gegevens</li>
                            <li [class.active]="currentSection === 'controle'" class="step">Controle melding</li>
                            <li *ngIf="isLocal" (click)="log()">log</li>
                        </ul>
                    </div>
                </div>
            </form>
        </fury-container>
    </fury-page-layout-content>
</fury-page-layout>
