import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'fury-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent {
  constructor() {
  }

  protected readonly environment = environment;
}

