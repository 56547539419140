import {version} from './version';

export const environment = {
  production: true,
  appVersionAddon: '',
  webappName: 'Milieu Klachten Centrale Meldformulier',
  api_endpoint: 'https://api.milieuklachtencentrale.nl/',
  homepage_url: 'https://www.milieuklachtencentrale.nl/',
  webform_url: 'https://meldformulier.milieuklachtencentrale.nl/',
  latitude: 51.55,
  longitude: 5.083333,
  sentry: {
    dsn: 'https://6147d4caa2ada6447bb96ab7ab414ce3@o4507735602298880.ingest.de.sentry.io/4507748310909008',
    version: version
  }
};
