import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {version} from './environments/version';

if (environment.production || environment.appVersionAddon === '-staging') {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.production ? 'live' : window.location.href.indexOf('localhost') === -1 ? 'staging' : 'local' ,
    release: version + environment.appVersionAddon,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
      }),
    ],
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.'
    ],
    // Performance Monitoring
    tracesSampleRate: 0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
