import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()

export class SnackbarService {

  constructor(private snackbar: MatSnackBar) {
  }

  success(message: string, duration: number = 2000) {
    this.snackbar.open(message, 'Sluiten', {
      duration: duration,
      panelClass: ['snackbar-success']
    });
  }

  info(message: string, duration: number = 5000) {
    this.snackbar.open(message, 'Sluiten', {
      duration: duration,
      panelClass: ['snackbar-info']
    });
  }

  // noinspection JSUnusedGlobalSymbols
  warning(message: string, duration: number = 5000) {
    this.snackbar.open(message, 'Sluiten', {
      duration: duration,
      panelClass: ['snackbar-warning']
    });
  }

  error(message: string, duration: number = 5000) {
    this.danger(message, duration);
  }

  danger(message: string, duration: number = 5000) {
    this.snackbar.open(message, 'Sluiten', {
      duration: duration,
      panelClass: ['snackbar-error']
    });
  }
}
