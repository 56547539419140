import {NgModule} from '@angular/core';
import {ScrollSpyDirective} from './scroll-spy.directive';

@NgModule({
  exports: [
    ScrollSpyDirective
  ],
  declarations: [
    ScrollSpyDirective
  ]
})
export class ScrollSpyModule {
}
