import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable()

export class ApiEndpointsService {

  private endpoints: { key: string, route: string }[] | [] = [
    {key: 'webform.report-types', route: 'v1/webform/report-types'},
    {key: 'webform.surroundings', route: 'v1/webform/surroundings'},
    {key: 'webform.reporter-types', route: 'v1/webform/reporter-types'},
    {key: 'webform.head-categories', route: 'v1/webform/head-categories/:id'},
    {key: 'webform.head-categories.main-categories', route: 'v1/webform/head-categories/:head_category_id/main-categories/:id'},
    {
      key: 'webform.head-categories.main-categories.sub-categories',
      route: 'v1/webform/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories'
    },
    {key: 'webform.companies', route: 'v1/webform/suspects/:search'},
    {key: 'webform.csrf-token', route: 'v1/webform/csrf-token'},
    {key: 'webform.reports-normal', route: 'v1/webform/reports/normal'},
    {key: 'webform.reports-company', route: 'v1/webform/reports/company'},
    {key: 'webform.reports-read', route: 'v1/webform/reports/:unique_number'},
    {key: 'webform.reports-failed', route: 'v1/webform/reports/failed'},

    {key: 'configuration.public-notifications.index', route: 'v1/public-notifications'},
    {key: 'configuration.public-notifications.read', route: 'v1/public-notifications/:id'},
  ];

  constructor() {
  }

  get(name: string, params?): string | null {
    const index: number = _.findIndex(this.endpoints, d => d.key === name);

    if (index !== -1) {
      if (params) {
        const url: string = this.endpoints[index].route;

        return url.replace(/(:[a-zA-Z_]+)/g, (m: string) => {
          return params[m];
        });
      } else {
        return this.endpoints[index].route;
      }
    }

    throw new Error('Unknown route URL requested: ' + name);
  }
}
