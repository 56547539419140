import {DOCUMENT} from '@angular/common';
import {Component, Inject, Renderer2} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {Platform} from '@angular/cdk/platform';
import {SplashScreenService} from '../@fury/services/splash-screen.service';
import {environment} from '../environments/environment';
import {version} from '../environments/version';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private splashScreenService: SplashScreenService, // required!! otherwise splashScreen does not go away on app load
  ) {
    this.renderer.addClass(this.document.body, 'fury-default');
    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }
    console.log(version + environment.appVersionAddon);

  }
}
