import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { PublicNotification } from '../../interfaces/configuration/public-notification';

@Injectable()

export class PublicNotificationsService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<PublicNotification | boolean> {
    return new Observable<PublicNotification | boolean>((o: Subscriber<PublicNotification | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('configuration.public-notifications.index')).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<PublicNotification>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<PublicNotification | boolean> {
    return new Observable<PublicNotification | boolean>((o: Subscriber<PublicNotification | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('configuration.public-notifications.read', {':id': id})).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<PublicNotification>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }
}
