import {Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {CdkScrollable, ScrollDispatcher} from '@angular/cdk/overlay';

@Directive({
  selector: '[furyScrollSpy]'
})
export class ScrollSpyDirective {

  @Input() public spiedTags: string[] = [];

  @Output() public sectionChange: EventEmitter<string> = new EventEmitter<string>();

  private currentSection: string;

  constructor(private _el: ElementRef,
              private scrollDispatcher: ScrollDispatcher) {
    this.scrollDispatcher.scrolled().subscribe((e: CdkScrollable): void => {
      if (typeof e !== 'undefined') {
        let currentSection: string;

        const children = this._el.nativeElement.children,
          scrollTop: number = e.measureScrollOffset('top');

        for (let i: number = 0; i < children.length; i++) {
          const element = children[i];
          if (this.spiedTags.some((spiedTag: string): boolean => spiedTag === element.tagName)) {
            if ((element.offsetTop - 150) <= scrollTop) {
              currentSection = element.id;
            }
          }
        }

        if (currentSection !== this.currentSection) {
          this.currentSection = currentSection;
          this.sectionChange.emit(this.currentSection);
        }
      }
    });
  }
}
