import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MaterialModule} from '../../../@fury/shared/material-components.module';
import {ScrollbarModule} from '../../../@fury/shared/scrollbar/scrollbar.module';
import {NormalFormComponent} from './normal-form.component';
import {LoadingOverlayModule} from '../../../@fury/shared/loading-overlay/loading-overlay.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FuryAlertBoxModule} from '../../../@fury/shared/alert-box/alert-box.module';
import {LightboxModule} from 'ng-gallery/lightbox';
import {RouterModule} from '@angular/router';
import {FurySharedModule} from '../../../@fury/fury-shared.module';
import {ReportProgressModule} from '../../../@fury/shared/report-progress/report-progress.module';
import {NormalFormRoutingModule} from './normal-form-routing.module';
import {ScrollSpyModule} from '../../../@fury/shared/scroll-spy/scroll-spy.module';
import {PdokService} from '../../services/external/pdok.service';
import {LeafletMapService} from '../../services/base/leaflet-map.service';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {NgxLoadingControlModule} from '@runette/ngx-leaflet-loading';
import {MaterialFileInputModule} from 'ngx-material-file-input';

@NgModule({
  imports: [
    CommonModule,
    NormalFormRoutingModule,
    MaterialModule,
    ScrollbarModule,
    LoadingOverlayModule,
    ReactiveFormsModule,
    FuryAlertBoxModule,
    LightboxModule,
    RouterModule,
    FurySharedModule,
    ReportProgressModule,
    ScrollSpyModule,
    LeafletModule,
    NgxLoadingControlModule,
    MaterialFileInputModule,
  ],
  declarations: [NormalFormComponent],
  exports: [NormalFormComponent],
  providers: [
    PdokService,
    LeafletMapService,
  ]
})

export class NormalFormModule {
}
