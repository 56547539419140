import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageLayoutDirective} from './page-layout.directive';
import {PageLayoutHeaderDirective} from './page-layout-header.directive';
import {PageLayoutContentDirective} from './page-layout-content.directive';
import {PageLayoutContentWidthDirective} from './page-layout-content-width.directive';
import {PageContainerDirective} from './page-container.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PageLayoutDirective,
    PageLayoutHeaderDirective,
    PageLayoutContentDirective,
    PageLayoutContentWidthDirective,
    PageContainerDirective,
  ],
  exports: [
    PageLayoutDirective,
    PageLayoutHeaderDirective,
    PageLayoutContentDirective,
    PageLayoutContentWidthDirective,
    PageContainerDirective,
  ]
})
export class PageLayoutModule {
}
