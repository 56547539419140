<div>
    <div>
        <span class="dot"></span>
        <span class="filename">{{ filename }}</span>
    </div>
    <div>
        <span class="size">{{ size | byteFormat }}</span>
        <span *ngIf="removable" class="remove">
      <button (click)="removeAttachment()" class="remove-attachment" type="button">Verwijder dit bestand</button>
    </span>
    </div>
</div>
