import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MaterialModule} from '../material-components.module';
import {ReportProgressComponent} from './report-progress.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [ReportProgressComponent],
  exports: [ReportProgressComponent]
})
export class ReportProgressModule {
}
